import React, { useEffect, useState } from "react";
import './FAQSection.css';
import Profile from "../../components/profile/profile";
import Navbar from "../../components/navbar/Navbar";

const FAQ = () => {

    const [activeIndex, setActiveIndex] = useState(null);
    const [userData, setUserData] = useState('');

    useEffect(() => {
        const user = window.Telegram.WebApp.initDataUnsafe?.user;
        if (user) {
        setUserData(user);
        }
    }, []);

  const faqItems = [
    {
      question: 'Как установить соединение?',
      answer: 'Подпробно о том, как установить VPN соединение на вашем устройстве рассказано <a href="https://teletype.in/@elr1cs_work/install-vpn">тут</a>',
    },
    {
      question: 'Как связаться с разработчиком?',
      answer: 'Любые предложения о сотрудничестве/улучшениях принимаются через бота',
    },
    {
      question: 'Могу ли я повлиять на развитие проекта?',
      answer: 'Да, конечно. Вы можете помочь развитию проекта своими добровольными пожертвованиями на один из криптокошельков. <a href="https://t.me/elr1cswork_bot">Подробнее в боте</a>',
    },
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

    return(
        <div>
            <Profile userData={userData.username}/>
            <section className="faq">
                {faqItems.map((item, index) => (
                    <div key={index} className="faq__item">
                        <button
                            className="faq__question"
                            onClick={() => toggleAnswer(index)}
                        >
                            {item.question}
                        </button>
                        {activeIndex === index && (
                            <p className="faq__answer" dangerouslySetInnerHTML={{ __html: item.answer }} />
                        )}
                    </div>
                ))}
            </section>
            <div className="spoiler">
                <p>
                    Примечание: “elr1cs_work” не собирает и не передает данные о пользователях ни одному провайдеру. Также не хранит их у себя на сервере или еще где-то. Приятного пользования;)
                </p>
            </div>
            <Navbar active_item={'faq'}/>
        </div>
        )
}

export default FAQ;