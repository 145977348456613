import React from "react";
import './Header.css';
import qr from './qr_profile.svg'

const Profile = ({userData}) => {
    return(<header className="header">
        <div className="header__logo-container">
          <img src={qr} alt="Logo" className="header__logo" />
          <div className="header__text">
            <span className="header__title">{userData}</span>
            <span className="header__subtitle">Бесплатный тариф</span>
          </div>
        </div>
      </header>)
}

export default Profile;