import React from "react";
import cl from './Connect.module.css'

const Connect = ({ userStatus }) => {

    function Conn ()  {
        window.Telegram.WebApp.HapticFeedback.impactOccurred("soft");
    }
    return (
        <div>
            {userStatus === "newUser" ? (
                <div className={cl.connectBlock}>
                    <p onClick={Conn}>Подключиться</p>
                </div>
            ) : (
                <div>
                    <p>Your status: {userStatus}</p>
                </div>
            )}
        </div>
    );
};

export default Connect;
