import React, { useEffect, useState } from "react";
import Preloader from "../../components/preloader/preloader";
import Navbar from "../../components/navbar/Navbar";
import Profile from "../../components/profile/profile";
import Connect from "../../components/connect/Connect";

const Main = () => {
    const [userData, setUserData] = useState('');
    const [requestStatus, setRequestStatus] = useState(null); // State to handle request status

    useEffect(() => {
        const user = window.Telegram.WebApp.initDataUnsafe?.user;
        if (user) {
            setUserData(user);
        }

        fetch('https://elr1c-admin.ru:2402/panel/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                username: 'elr1c180',
                password: '17936535Aa-'
            })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status);
            }
            return response.json();
        })
        .then(data => {
            setRequestStatus('Успешно отправлен запрос'); 
            console.log('Ответ от сервера:', data);
        })
        .catch(error => {
            setRequestStatus(`Ошибка: ${error.message}`);
            console.error('Ошибка:', error);
        });
    }, []);

    return (
        <div>
            {/* {requestStatus && (
                <div style={{ color: requestStatus.startsWith('Ошибка') ? 'red' : 'green' }}>
                    {requestStatus}
                </div>
            )} */}
            <Profile userData={userData.username} />
            <Connect userStatus={'newUser'} />
            <Navbar active_item={'qr'} />
        </div>
    );
};

export default Main;
