import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import cl from './Navbar.module.css';
import faq from './FAQ.svg';
import qr_main from './qr_main.svg';
import faq_active from './faq_active.svg';
import qr_active from './qr_active.svg';

const Navbar = ({active_item}) => {
  const [activeButton, setActiveButton] = useState(active_item); 
  const navigate = useNavigate();


  const handleToggle = (button) => {
    setActiveButton(button);
    if (button === 'qr') {
      navigate('/');
    } else if (button === 'faq') {
      navigate('/faq/');
    }
  };

  return (
    <div className={cl.NavbarWrap}>
      <div className={cl.NavbarBack}>
        <img
          src={activeButton === 'qr' ? qr_active : qr_main}
          className={activeButton === 'qr' ? cl.active : ''}
          alt="main_link"
          onClick={() => handleToggle('qr')}
        />
        <img
          src={activeButton === 'faq' ? faq_active : faq}
          className={activeButton === 'faq' ? cl.active : ''}
          alt="faq_link"
          onClick={() => handleToggle('faq')}
        />
      </div>
    </div>
  );
};

export default Navbar;
