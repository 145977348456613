import React from "react";
import cl from './preloader.module.css'

const Preloader = () => {

    return(
        <div className={cl.PreloaderWrap}>
            <div className={cl.PreloaderHeader}>
                elr1cs_work
            </div>
        </div>
    )
}

export default Preloader;