import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main/Main';
import FAQ from './pages/FAQ/FAQ';


function App() {

  window.Telegram.WebApp.setBackgroundColor('#1A0909');
  window.Telegram.WebApp.setHeaderColor('#B31217');
  
  const tele = window.Telegram.WebApp;
  tele.expand()

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Main/>}/>
        <Route path="/main" element={<Main/>} />
        <Route path="/faq" element={<FAQ/>} />  
      </Routes>
    </BrowserRouter>
  );
}

export default App;
